import http from "@/utils/http";

/*
 * 智控策略-----控制设定下发
 * @param {*} params
 * @returns
 */
export function apiDeviceCmdWrite(data) {
  return http.request({
    url: "/cemp-monitor/device-cmd/write",
    method: "post",
    data,
  });
}

/*
 * 智控策略-----获取房间类型
 * @param {*} params
 * @returns
 */
export function apiRoomType(params) {
  return http.request({
    url: "/cemp-energy/station/region/type",
    method: "get",
    params,
  });
}



/*
 * 智控策略-----视图tab
 * @param {*} params
 * @returns
 */
export function apiViewTab(params) {
  return http.request({
    url: "/cemp-monitor/view/tab",
    method: "get",
    params,
  });
}

/*
 * 智控策略-----锦江---查询区域
 * @param {*} params
 * @returns
 */
export function apiJinJiangReginList(params) {
  return http.request({
    url: "/cemp-energy/station/region/list",
    method: "get",
    params,
  });
}

/*
 * 智控策略-----锦江---当前设备状态值
 * @param {*} params
 * @returns
 */
export function apiJinJiangDeviceStatus(params) {
  return http.request({
    url: "/cemp-energy/station/device/current/status",
    method: "get",
    params,
  });
}
/*
 * 智控策略-----锦江---最近1个月日统计
 * @param {*} params
 * @returns
 */
export function apiJinJiangDeviceMonthStatus(params) {
  return http.request({
    url: "/cemp-energy/station/device/month/status",
    method: "get",
    params,
  });
}
/*
 * 智控策略-----锦江---当日实时数据
 * @param {*} params
 * @returns
 */
export function apiJinJiangDeviceTodayStatus(params) {
  return http.request({
    url: "/cemp-energy/station/device/today/status",
    method: "get",
    params,
  });
}

// 项目管理
export const apiProject = {
  page(params) {
    return http.request({
      url: "/cemp-monitor/project/list",
      method: "get",
      params,
    });
  },
  currentNode() {
    return http.request({
      url: "/cemp-bms/project/node/current-user/tree",
      method: "get",
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-monitor/project",
      method: "put",
      data,
    });
  },
  detail(id) {
    return http.request({
      url: `/cemp-monitor/project/${id}`,
      method: "get",
    });
  },
};

// 项目管理 - 设备列表
export const apiDevice = {
  page(data) {
    return http.request({
      url: "/cemp-monitor/device-monitor/listByProject",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-monitor/device-monitor/edit",
      method: "post",
      data,
    });
  },
  parameterList(productId) {
    return http.request({
      url: "/cemp-bms/product/device-parameter/listByProduct",
      method: "get",
      params: {
        productId,
      },
    });
  },
};

// 空间管理
export const apiSpace = {
  list(projectId) {
    return http.request({
      url: `/cemp-monitor/project/space/spaces/${projectId}`,
      method: "get",
    });
  },
  delete(spaceId) {
    return http.request({
      url: `/cemp-monitor/project/space/${spaceId}`,
      method: "delete",
    });
  },
  add(data) {
    return http.request({
      url: `/cemp-monitor/project/space`,
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: `/cemp-monitor/project/space`,
      method: "put",
      data,
    });
  },
};
