export const checkPhone = (value) => {
  const reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
  return reg.test(value);
};
export const checkEmail = (value) => {
  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  return reg.test(value);
};

// code由字母、数字组成，首位只能为字母,不能超过8位
export const codeReg = /^[a-zA-Z]{1}[a-zA-Z0-9]{0,7}$/