import http from '../utils/http'

export const apiTenant = {
    // 租户列表
    list(params) {
        return http.request({
            url: '/oauth/tenant/tenants',
            method: 'get',
            params
        })
    },
    // 租户菜单
    menus(params) {
        return http.request({
            url: '/oauth/menu/system/module/menus',
            method: 'get',
            params
        })
    },
    // 校验基本信息参数
    validation(params) {
        return http.request({
            url: `/oauth/tenant/param/validation`,
            method: 'get',
            params
        })
    },
    // 新增租户
    add(data) {
        return http.request({
            url: `/oauth/tenant/creation`,
            method: 'post',
            data
        })
    },
    // 编辑租户
    edit(data) {
        return http.request({
            url: `/oauth/tenant/basic/change`,
            method: 'put',
            data
        })
    },
    // 删除租户
    delete(tenantId) {
        return http.request({
            url: `/oauth/tenant/${tenantId}`,
            method: 'delete',
        })
    },
    // 租户详情
    detail(tenantId) {
        return http.request({
            url: `/oauth/tenant/basic/${tenantId}`,
            method: 'get',
        })
    },
}



export const apiEnterprise = {
    // 企业基本信息
    detail(tenantId) {
        return http.request({
            url: '/oauth/enterprise/basic',
            method: 'get',
            params: { tenantId }
        })
    },
    edit(data) {
        return http.request({
            url: '/oauth/enterprise/basic',
            method: 'put',
            data
        })
    },
    // 获取企业下的用户列表
    userList(tenantId) {
        return http.request({
            url: '/oauth/user/users/tenant',
            method: 'get',
            params: { tenantId }
        })
    },
    // 获取企业下的用户列表,不排除登陆人
    userList2(tenantId) {
        return http.request({
            url: '/oauth/user/users/tenant/v2',
            method: 'get',
            params: { tenantId }
        })
    },
    // 移交企业管理员
    transferEnterpriseAdmin(data) {
        return http.request({
            url: '/oauth/enterprise/admin/change',
            method: 'put',
            data
        })
    },
    // 提交后角色列表
    transferRoleList() {
        return http.request({
            url: '/oauth/role/list',
            method: 'get',
        })
    }

}

// 企业项目
export const apiEnterpriseProject = {
    list(tenantId) {
        return http.request({
            url: '/cemp-bms/management-node/list',
            method: 'get',
            params: { tenantId }
        })
    },
    add(data) {
        return http.request({
            url: '/cemp-bms/management-node',
            method: 'post',
            data
        })
    },
    edit(data) {
        return http.request({
            url: '/cemp-bms/management-node',
            method: 'put',
            data
        })
    },
    delete(id) {
        return http.request({
            url: `/cemp-bms/management-node/${id}`,
            method: 'delete'
        })
    },
}