import { checkPhone, checkEmail } from "@/utils/validate";

export const validatePhone = async (_rule, value) => {
  if (value) {
    if (checkPhone(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject("请输入11位有效手机号");
    }
  }
};
export const validateEmail = async (_rule, value) => {
  if (value) {
    if (checkEmail(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject("请输入有效的国内邮箱");
    }
  }
};
export const validatelonLat = (_rule, value) => {
  if (value) {
    if (!value.includes(',')) {
      return Promise.reject("经纬度需要用逗号隔开");
    } else {
      let lonlat = value.split(',')
      // eslint-disable-next-line no-useless-escape
      const reg1 = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/
      // eslint-disable-next-line no-useless-escape
      const reg2 = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/
      if (!reg1.test(lonlat[0])) {
        return Promise.reject("请输入正确的经度");
      } else if (!reg2.test(lonlat[1])) {
        return Promise.reject("请输入正确的纬度");
      }
    }
    return Promise.resolve();
  }
}