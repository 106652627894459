<template>
  <BasicModal
    title="修改项目"
    width="850px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <div class="project-edit-content">
      <BasicForm
        class="project-edit-form"
        ref="formRef1"
        :labelCol="{ span: 6 }"
        :formItemsMap="formItemsMap1"
        v-model:formData="formData1"
      ></BasicForm>
      <BasicForm
        class="project-edit-form"
        ref="formRef2"
        :labelCol="{ span: 6 }"
        :formItemsMap="formItemsMap2"
        v-model:formData="formData2"
        @change="handleChange"
      ></BasicForm>
    </div>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { apiProject } from "@/api/IoT/project";
import { apiDictListByCode } from "@/api/common.js";
import { validatelonLat } from "@/components/basic/form/validator";
import { apiEnterprise } from "@/api/tenant.js";
import { useUserStore } from "@/store/modules/user";

const userStore = useUserStore();

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);
const formRef2 = ref(null);

onMounted(() => {
  initFormItems();
  initFormData();
});

const formItemsMap1 = reactive({
  name: {
    label: "项目名称",
    prop: "name",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  bizType: {
    label: "项目业态",
    prop: "bizType",
    type: "select",
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  code: {
    label: "项目编码",
    prop: "code",
    type: "input",
    required: true,
    requiredMessage: "请输入",
    disabled: true,
  },
  area: {
    label: "建筑面积",
    prop: "area",
    type: "input",
    suffix: "㎡",
    inputType: "number",
  },
  parentBizNodeId: {
    label: "所属节点",
    prop: "parentBizNodeId",
    type: "treeSelect",
    fieldNames: {
      children: "children",
      label: "name",
      value: "bizNodeId",
    },
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  energyType: {
    label: "能耗类型",
    prop: "energyType",
    type: "select",
    mode: "multiple",
    maxTagCount: 4,
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  status: {
    label: "项目阶段",
    prop: "status",
    type: "select",
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  energySubSystem: {
    label: "能源子系统",
    prop: "energySubSystem",
    type: "select",
    mode: "multiple",
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
});
const formItemsMap2 = reactive({
  directorUserId: {
    label: "项目负责人",
    prop: "directorUserId",
    type: "select",
    options: [],
  },
  mobile: {
    label: "负责人电话",
    prop: "mobile",
    type: "input",
    disabled:true
    // rules: [{ validator: validatePhone, trigger: "blur" }],
  },
  addressCode: {
    label: "省市县区",
    prop: "addressCode",
    type: "addressCascader",
  },
  lonLat: {
    label: "经纬度",
    prop: "lonLat",
    type: "input",
    placeholder:'请输入经纬度,格式：89.14,32.45',
    required:true,
    rules: [{ validator: validatelonLat, trigger: "blur" }],
  },
  address: {
    label: "项目地址",
    prop: "address",
    type: "textarea",
  },
  description: {
    label: "项目描述",
    prop: "description",
    type: "textarea",
  },
});
const initFormItems = async () => {
  apiDictListByCode("PROJECT_BIZ_TYPE").then((res) => {
    formItemsMap1.bizType.options = res.result;
  });
  apiDictListByCode("PROJECT_STATUS").then((res) => {
    formItemsMap1.status.options = res.result;
  });
  apiProject.currentNode().then((res) => {
    formItemsMap1.parentBizNodeId.options = [res.result];
  });
  apiDictListByCode("ENERGY_TYPE").then((res) => {
    formItemsMap1.energyType.options = res.result;
  });
  apiDictListByCode("ENERGY_SUB_SYSTEM").then((res) => {
    formItemsMap1.energySubSystem.options = res.result;
  });
  apiEnterprise.userList2(userStore.userInfo.tenantId).then(res => {
    formItemsMap2.directorUserId.options = res.result.map(e=>{
      return {
        ...e,
        label:e.nickname,
        value:e.userId
      }
    })
  })
};
const formData1 = reactive({
  name: "",
  bizType: undefined,
  code: "",
  area: "",
  parentBizNodeId: undefined,
  energyType: [],
  status: undefined,
  energySubSystem: []
});
const formData2 = reactive({
  directorUserId: "",
  mobile: "",
  addressCode: [],
  lonLat: '0,0',
  address: "",
  description: "",
});
const initFormData = async () => {
  if (!props.detail) return;
  for (const key in formData1) {
    formData1[key] = props.detail[key];
  }
  for (const key in formData2) {
    if (key === "lonLat") {
      formData2[key] = props.detail.gdLongitude + ',' + props.detail.gdLatitude;
    } else {
      formData2[key] = props.detail[key];
    }
  }
};

const handleChange = ({prop,value}) => {
  if (prop === 'directorUserId') {
    let user = formItemsMap2.directorUserId.options.find(e=>e.userId === value)
    formData2.mobile = user ? user.mobile : ''
  }
}

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef1.value.formRef.validateFields();
    await formRef2.value.formRef.validateFields();
    const params = {
      id: props.detail.id,
      ...formData1,
      ...formData2,
    };
    let lonLat =  params.lonLat.split(',')
    params.gdLongitude = lonLat[0];
    params.gdLatitude = lonLat[1];
    confirmLoading.value = true;
    await apiProject.edit(params);
    message.success(`修改成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.project-edit-content {
  display: flex;
  gap: 20px;
}
.project-edit-form {
  flex: 1;
}
</style>
