import http from "@/utils/http";

// 项目管理
export const apiProject = {
  page(params) {
    return http.request({
      url: "/cemp-bms/project/list",
      method: "get",
      params,
    });
  },
  delete(id) {
    return http.request({
      url: `/cemp-bms/project/${id}`,
      method: "delete",
    });
  },
  currentNode() {
    return http.request({
      url: "/cemp-bms/project/node/current-user/tree",
      method: "get",
    });
  },
  add(data) {
    return http.request({
      url: "/cemp-bms/project",
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: "/cemp-bms/project",
      method: "put",
      data,
    });
  },
  detail(id) {
    return http.request({
      url: `/cemp-bms/project/${id}`,
      method: "get",
    });
  },
};

// 空间管理
export const apiSpace = {
  list(projectId) {
    return http.request({
      url: `/cemp-bms/project/space/spaces/${projectId}`,
      method: "get",
    });
  },
  delete(spaceId) {
    return http.request({
      url: `/cemp-bms/project/space/${spaceId}`,
      method: "delete",
    });
  },
  add(data) {
    return http.request({
      url: `/cemp-bms/project/space`,
      method: "post",
      data,
    });
  },
  edit(data) {
    return http.request({
      url: `/cemp-bms/project/space`,
      method: "put",
      data,
    });
  },
};
